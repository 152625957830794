import { captureMessage } from '@sentry/react'
import { AxiosError, isAxiosError } from 'axios'
import i18n from 'i18next'
import type {
  Connector,
  ConnectorCredential,
  ExecutionErrorCode,
} from 'pluggy-js'

import { ConnectConfig } from '../../../../lib/authApiClient'
import { getServerSyncedDate } from '../../../../modules/config/utils'
import { ConnectorId, isQRConnector } from '../../../../modules/connector/utils'
import { SupportedLanguage } from '../../../../utils/i18n'
import { ConnectFormFieldProps } from './ConnectForm'
import { MfaStage } from './ConnectForm.types'

export type FieldValues = Record<string, string>

export type FieldErrors = Record<string, string | undefined>

const DEFAULT_MFA_TIMEOUT = 90000

/**
 * Throws an error if field is required (not optional), but no value
 * has been filled in.
 *
 * @param fieldDefinition
 * @param value
 */
function checkFieldRequiredError(
  fieldDefinition: ConnectFormFieldProps,
  value: string,
) {
  const { label, isOptional } = fieldDefinition
  const isEmpty = value === ''
  const isRequired = !isOptional
  const isFieldRequiredEmpty = isRequired && isEmpty

  if (isFieldRequiredEmpty) {
    // field required error, non-optional field was not filled
    throw new Error(i18n.t('connectorForm.field-error.required', { label }))
  }
}

/**
 * Validate UI field current value against it's 'validation' regex (if it has been specified),
 * and throw error if failed.
 *
 * @param fieldDefinition
 * @param value
 */
function checkFieldRegexValidationError(
  fieldDefinition: ConnectFormFieldProps,
  value: string,
) {
  const { validation, validationMessage, label, isOptional } = fieldDefinition
  const isEmpty = value === ''

  if (!validation || !validationMessage || (isOptional && isEmpty)) {
    // no validation to check, skip
    return
  }

  // check regex validation on current value
  // if not pass, throw error with validationMessage
  let regex: RegExp

  try {
    regex = new RegExp(validation)
  } catch (error) {
    // validation string was an invalid regex - we ignore it (no error thrown)
    console.warn(`Field '${label}' has an invalid validation`, validation)
    captureMessage(
      `Field '${label}' has an invalid validation: ${error.message}`,
      {
        contexts: {
          errorContext: {
            fieldDefinition,
          },
        },
      },
    )
    return
  }
  const isValid = regex.test(String(value))

  if (!isValid) {
    // validation error -> throw to update UI formErrors state
    throw new Error(validationMessage)
  }
}

/**
 * Check if the current value for the specified field definition, is valid.
 * If not, an error is thrown with an error message to be shown in the field UI.
 *
 * @param field - the field definitions, validation regex, isOptional, etc.
 * @param value - the current value in state of the field
 */
export function validateField(
  field: ConnectFormFieldProps,
  value: string,
): void {
  checkFieldRequiredError(field, value)
  checkFieldRegexValidationError(field, value)
}

export function validateFields(
  inputValues: FieldValues,
  inputFields: ConnectFormFieldProps[],
): FieldErrors {
  const errors: FieldErrors = {}
  for (const inputField of inputFields) {
    const { name } = inputField
    const inputValue = inputValues[name]

    try {
      validateField(inputField, inputValue)
      // no error
    } catch (error) {
      // field validation error
      errors[name] = error.message
    }
  }
  return errors
}

// helper type to create an union as a subset from other union (source: https://stackoverflow.com/a/53637746/6279385)
type Extends<T, U extends T> = U

/**
 * Subset of ExecutionErrorCode that are related to the user's account.
 * These errors should display an error message in the ConnectForm
 * screen (instead of in the ConnectStatus screen).
 *
 * @type {readonly string[]}
 */
const userAccountErrors = [
  'INVALID_CREDENTIALS',
  'ACCOUNT_CREDENTIALS_RESET',
  'INVALID_CREDENTIALS_MFA',
  'USER_INPUT_TIMEOUT',
  'ALREADY_LOGGED_IN',
  'ACCOUNT_LOCKED',
  'ACCOUNT_NEEDS_ACTION',
  'USER_NOT_SUPPORTED',
] as const

/**
 * UserAccountError is a subset of ExecutionErrorCode enum possible values
 */
type UserAccountError = Extends<
  ExecutionErrorCode,
  (typeof userAccountErrors)[number]
>

/**
 * This funtions is a type-guard, this means that performs a runtime
 * check that guarantees the type in the scope. In this case, if it's
 * an error that is not from the connector and cames from the user account
 * @param errorCode - code of the current error
 * @returns - boolean
 */
export function isUserAccountError(
  errorCode: ExecutionErrorCode | null,
): errorCode is UserAccountError {
  return userAccountErrors.includes(errorCode as UserAccountError)
}

export function mapExecutionErrorI18nKey(
  errorCode: UserAccountError,
  connector: Connector,
): string {
  if (errorCode === 'INVALID_CREDENTIALS') {
    if (isQRConnector(connector)) {
      return 'connectorForm.error.invalid_credentials_inter-qr'
    }
    return 'connectorForm.error.invalid_credentials'
  }
  if (errorCode === 'ACCOUNT_CREDENTIALS_RESET') {
    return 'connectorForm.error.account_credentials_reset'
  }
  if (
    errorCode === 'INVALID_CREDENTIALS_MFA' ||
    errorCode === 'USER_INPUT_TIMEOUT'
  ) {
    return 'connectorForm.error.invalid_credentials_mfa'
  }
  if (errorCode === 'ALREADY_LOGGED_IN') {
    return 'connectorForm.error.already_logged_in'
  }
  if (errorCode === 'ACCOUNT_LOCKED') {
    return 'connectorForm.error.account_locked'
  }
  if (errorCode === 'USER_NOT_SUPPORTED') {
    return 'connectorForm.error.user_not_supported'
  }
  // code === 'ACCOUNT_NEEDS_ACTION'
  return 'connectorForm.error.account_needs_action'
}

/**
 * if the message contains an email, we get the provider using regex and returns it
 * supported emails (most usage): gmail, hotmail and yahoo
 * @param message - message of the connector
 * @returns
 */
export function getEmailProviderFromMessage(message: string | undefined): {
  emailProviderUrl: string
  emailDomainName?: string
} {
  let emailProviderUrl = 'https://mail.google.com/'
  let emailDomainName: string | undefined = undefined

  if (!message) {
    return {
      emailProviderUrl,
      emailDomainName,
    }
  }
  // match string that is between an @ and a .
  // source: https://stackoverflow.com/a/39027268/6279385
  const regex = new RegExp(/@(\w+)/)

  emailDomainName = message.match(regex)?.[1]

  if (emailDomainName === 'hotmail' || emailDomainName === 'outlook') {
    emailProviderUrl = 'https://outlook.live.com/'
  }
  if (emailDomainName === 'yahoo') {
    emailProviderUrl = 'https://mail.yahoo.com'
  }

  return {
    emailProviderUrl,
    emailDomainName,
  }
}

const DEFAULT_I18N_KEY = 'connectorForm.help-link.text'

/**
 * Returns the help links and texts for the selected connector
 */
export function getHelpLinksByConnector(selectedConnector: Connector):
  | {
      link: string
      i18nKey: string
    }[]
  | undefined {
  const selectedConnectorId = selectedConnector.id

  if (selectedConnectorId === ConnectorId.BR_BUSINESS_INTER) {
    return [
      {
        link: 'https://docs.pluggy.ai/docs/tutorial-banco-inter-empresas',
        i18nKey: DEFAULT_I18N_KEY,
      },
    ]
  }
  if (selectedConnectorId === ConnectorId.BR_BUSINESS_BRADESCO) {
    return [
      {
        link: 'https://docs.pluggy.ai/docs/tutorial-banco-bradesco-empresas',
        i18nKey: DEFAULT_I18N_KEY,
      },
    ]
  }
  if (selectedConnectorId === ConnectorId.BR_BUSINESS_BANCO_DO_BRASIL) {
    return [
      {
        link: 'https://docs.pluggy.ai/docs/tutorial-banco-brasil-empresas',
        i18nKey: DEFAULT_I18N_KEY,
      },
    ]
  }
  if (selectedConnectorId === ConnectorId.BR_BUSINESS_ITAU) {
    return [
      {
        link: 'https://docs.pluggy.ai/docs/tutorial-banco-itau-empresas',
        i18nKey: DEFAULT_I18N_KEY,
      },
    ]
  }
  if (selectedConnectorId === ConnectorId.BR_BUSINESS_SICOOB) {
    return [
      {
        link: 'https://docs.pluggy.ai/docs/tutorial-banco-sicoob-empresas',
        i18nKey: DEFAULT_I18N_KEY,
      },
    ]
  }
  if (selectedConnectorId === ConnectorId.BR_BUSINESS_SICREDI) {
    return [
      {
        link: 'https://docs.pluggy.ai/docs/tutorial-banco-sicredi-empresas',
        i18nKey: DEFAULT_I18N_KEY,
      },
    ]
  }
  if (selectedConnectorId === ConnectorId.BR_BUSINESS_SANTANDER) {
    return [
      {
        link: 'https://docs.pluggy.ai/docs/tutorial-banco-santander-empresas',
        i18nKey: DEFAULT_I18N_KEY,
      },
    ]
  }
  if (selectedConnectorId === ConnectorId.BR_INVESTMENT_MERCADO_BITCOIN) {
    return [
      {
        link: 'https://central.ajuda.mercadobitcoin.com.br/servicedesk/customer/portal/22/article/667811896',
        i18nKey: DEFAULT_I18N_KEY,
      },
    ]
  }

  return undefined
}

export enum FORM_NAME {
  LOGIN = 'login',
  LOGIN_MFA_2_STEP = 'login-mfa-2-step',
  ACCOUNT_FLOW_SELECTION = 'account-flow-selection',
  COOPERATIVE_FLOW_SELECTION = 'cooperative-flow-selection',
}

/**
 * Returns the error message from the request error
 *
 * @param error - the error object
 * @returns - the error message or null if it's not an api error
 */
export function getApiErrorResponseMessage(
  error: string | Error | AxiosError<unknown> | null,
): string | null {
  if (!isAxiosError(error)) {
    return null
  }

  const { response } = error as AxiosError<{ message: string }>

  if (!response) {
    return null
  }

  const {
    data: { message },
  } = response

  return message
}

/**
 * Helper to get MFA timer value for countdown
 * using the date from  auth-api
 *
 * @param mfaFormCredential - credential with stage MFA with expiredAt date
 * @param serverDateDiff - difference between the date from the api and the local date
 * @returns - remaining time in seconds or null if it fails to fetch the date
 */
export async function getMfaInitialRemainingTime(
  mfaFormCredential: ConnectorCredential & {
    stage: MfaStage
  },
  serverDateDiff: number | null,
): Promise<number | null> {
  const dateTime = getServerSyncedDate(serverDateDiff).getTime()

  const defaultMfaExpiresAt = new Date(dateTime + DEFAULT_MFA_TIMEOUT)

  const { expiresAt } = mfaFormCredential
  const currentMfaExpiresAt = expiresAt || defaultMfaExpiresAt

  return Math.max(currentMfaExpiresAt.getTime() - dateTime, 0)
}

/**
 * helper to override only some button texts with the custom ones coming from API
 * @param i18nKey
 * @param customFormButtonText
 * @returns - the custom text or the default one
 */
export function getConnectButtonText(
  i18nKey: string,
  customFormButtonText:
    | ConnectConfig['text']['button']['connectFormStep']
    | null,
): string {
  const currentLanguage = i18n.language as SupportedLanguage

  if (!customFormButtonText) {
    return i18n.t(i18nKey)
  }

  if (i18nKey === 'connectorForm.submit') {
    return customFormButtonText.submit[currentLanguage]
  }
  if (i18nKey === 'connectorForm.connecting') {
    return customFormButtonText.submitLoading[currentLanguage]
  }
  return i18n.t(i18nKey)
}

/**
 * Helper to determine if a connector credential/parameter corresponds
 * to a file upload input, based on its name.
 *
 * @param {string} name - the field name
 * @returns {boolean}
 */
export function isFileUploadInput({
  name,
}: Pick<ConnectFormFieldProps, 'name'>): boolean {
  return name === 'privateKey' || name === 'certificate'
}

/**
 * Naive helper to extract file extension(s) from a text, recognized as
 * any 3 or 4 letters after a '.', before starting a new word.
 *
 * @param {string} text
 * @returns {string[] | undefined}
 */
export function extractFileExtensions(text: string): string[] | undefined {
  return text.match(/\.\w{3,4}\b/g) || undefined
}
