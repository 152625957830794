/* eslint-disable import/no-unused-modules */
import { captureMessage, Severity } from '@sentry/react'
import {
  ExecutionErrorResult,
  ExecutionStatus,
  Item,
  ItemProductsStatusDetail,
} from 'pluggy-js'

import { EXECUTION_ERROR } from '../../../../modules/item/types'

export enum CONNECTION_STEP {
  UNKNOWN = 0,
  LOGIN_IN_PROGRESS = 1,
  ACCOUNT_IN_PROGRESS = 2,
  CREDITCARD_IN_PROGRESS = 3,
  TRANSACTION_IN_PROGRESS = 4,
  INVESTMENTS_IN_PROGRESS = 5,
  OPPORTUNITIES_IN_PROGRESS = 6,
  IDENTITY_IN_PROGRESS = 7,
  PORTFOLIO_IN_PROGRESS = 8,
  FINISHED = 9,
}

const connectionStepMap: Record<CONNECTION_STEP, { i18nKey: string }> = {
  [CONNECTION_STEP.LOGIN_IN_PROGRESS]: {
    i18nKey: 'connectStatus.stepStatus.login',
  },
  [CONNECTION_STEP.ACCOUNT_IN_PROGRESS]: {
    i18nKey: 'connectStatus.stepStatus.account',
  },
  [CONNECTION_STEP.CREDITCARD_IN_PROGRESS]: {
    i18nKey: 'connectStatus.stepStatus.credit-cards',
  },
  [CONNECTION_STEP.TRANSACTION_IN_PROGRESS]: {
    i18nKey: 'connectStatus.stepStatus.transactions',
  },
  [CONNECTION_STEP.INVESTMENTS_IN_PROGRESS]: {
    i18nKey: 'connectStatus.stepStatus.investments',
  },
  [CONNECTION_STEP.OPPORTUNITIES_IN_PROGRESS]: {
    i18nKey: 'connectStatus.stepStatus.opportunities',
  },
  [CONNECTION_STEP.IDENTITY_IN_PROGRESS]: {
    i18nKey: 'connectStatus.stepStatus.identity',
  },
  [CONNECTION_STEP.PORTFOLIO_IN_PROGRESS]: {
    i18nKey: 'connectStatus.stepStatus.portfolio',
  },
  [CONNECTION_STEP.FINISHED]: {
    i18nKey: 'connectStatus.stepStatus.finished',
  },
  [CONNECTION_STEP.UNKNOWN]: {
    i18nKey: 'connectStatus.stepStatus.unknown',
  },
}

export const connectionErrorsMap: Record<EXECUTION_ERROR, { i18nKey: string }> =
  {
    ERROR: {
      i18nKey: 'connectStatus.errorState.error',
    },
    CREATE_ERROR: {
      i18nKey: 'connectStatus.errorState.error',
    },
    SITE_NOT_AVAILABLE: {
      i18nKey: 'connectStatus.errorState.siteNotAvailable',
    },
    ACCOUNT_LOCKED: {
      i18nKey: 'connectStatus.errorState.accountLocked',
    },
    ACCOUNT_NEEDS_ACTION: {
      i18nKey: 'connectStatus.errorState.accountNeedsAction',
    },
    CONNECTION_ERROR: {
      i18nKey: 'connectStatus.errorState.connectionError',
    },
  }

export function isExecutionError(
  step: CONNECTION_STEP | EXECUTION_ERROR | ExecutionStatus,
): step is EXECUTION_ERROR {
  return Object.keys(connectionErrorsMap).some(
    (executionError) => executionError === step,
  )
}

function isItemError(itemStatus: string): boolean {
  return itemStatus === 'OUTDATED'
}

/**
 * Map an item.error.code {ExecutionErrorCode} to the corresponding ExecutionError.
 *
 * Note: This should not be needed, as the executionStatus should already be properly mapped,
 * but in some cases when the execution is too fast (ie. Pluggy Bank), this is failing.
 *  Ticket: https://pluggy.atlassian.net/browse/CAPI-327
 *
 * @param executionError
 */
function mapExecutionErrorCodeToExecutionError(
  executionError: ExecutionErrorResult,
): EXECUTION_ERROR | undefined {
  const { code } = executionError
  if (code === 'UNEXPECTED_ERROR') {
    return 'ERROR'
  }
  if (code === 'CONNECTION_ERROR') {
    return 'CONNECTION_ERROR'
  }
  if (code === 'SITE_NOT_AVAILABLE') {
    return 'SITE_NOT_AVAILABLE'
  }
  return undefined
}

/**
 * Determines the current UI state Step (or finish error result)
 * based on the item.executionStatus and item.status
 *
 * @param item
 */
export function mapExecutionStatusToStep(
  item: Item,
): CONNECTION_STEP | EXECUTION_ERROR {
  const {
    executionStatus: status,
    status: itemStatus,
    error: executionError,
  } = item

  if (executionError !== null) {
    const mappedExecutionError =
      mapExecutionErrorCodeToExecutionError(executionError)
    if (mappedExecutionError) {
      return mappedExecutionError
    }
  }
  if (isExecutionError(status)) {
    return status
  }
  if (isItemError(itemStatus)) {
    return 'ERROR'
  }
  if (status === 'LOGIN_IN_PROGRESS') {
    return CONNECTION_STEP.LOGIN_IN_PROGRESS
  }
  if (status === 'ACCOUNTS_IN_PROGRESS') {
    return CONNECTION_STEP.ACCOUNT_IN_PROGRESS
  }
  if (status === 'CREDITCARDS_IN_PROGRESS') {
    return CONNECTION_STEP.CREDITCARD_IN_PROGRESS
  }
  if (
    status === 'TRANSACTIONS_IN_PROGRESS' ||
    status === 'PAYMENT_DATA_IN_PROGRESS'
  ) {
    return CONNECTION_STEP.TRANSACTION_IN_PROGRESS
  }
  if (
    status === 'INVESTMENTS_IN_PROGRESS' ||
    status === 'INVESTMENTS_TRANSACTIONS_IN_PROGRESS'
  ) {
    return CONNECTION_STEP.INVESTMENTS_IN_PROGRESS
  }
  if (status === 'OPPORTUNITIES_IN_PROGRESS') {
    return CONNECTION_STEP.OPPORTUNITIES_IN_PROGRESS
  }
  if (status === 'IDENTITY_IN_PROGRESS') {
    return CONNECTION_STEP.IDENTITY_IN_PROGRESS
  }
  if (status === 'PORTFOLIO_IN_PROGRESS') {
    return CONNECTION_STEP.PORTFOLIO_IN_PROGRESS
  }
  if (status === 'SUCCESS' || status === 'PARTIAL_SUCCESS') {
    return CONNECTION_STEP.FINISHED
  }
  captureMessage(`Unknown execution status: '${status}'`, {
    level: Severity.Info,
    fingerprint: [status],
    contexts: {
      arguments: {
        status,
        itemStatus,
      },
    },
  })
  return CONNECTION_STEP.UNKNOWN
}

/**
 * Retrieve the i18n key for the current Connection Step to be shown.
 * If hiding the step detail, instead of returning the product step name return 'UNKNOWN'.
 *
 * @param {CONNECTION_STEP} currentConnectionStep
 * @param {boolean} hideConnectStatusStepDetail
 * @return {string}
 */
export function resolveStepToShowI18nKey(
  currentConnectionStep: CONNECTION_STEP,
  hideConnectStatusStepDetail: boolean,
): string {
  // all steps are 'product' steps except 'Login' and 'Finished' ones
  const isProductStep =
    currentConnectionStep !== CONNECTION_STEP.LOGIN_IN_PROGRESS &&
    currentConnectionStep !== CONNECTION_STEP.FINISHED

  const stepToShow =
    hideConnectStatusStepDetail && isProductStep
      ? CONNECTION_STEP.UNKNOWN
      : currentConnectionStep

  return connectionStepMap[stepToShow].i18nKey
}

/**
 * Validate if the user needs to authorize products
 * @param item
 * @returns true if the user needs to authorize products
 */
export function validateIfNeedsToAuthorizeProducts(item: Item): boolean {
  const PRODUCT_KEYS = [
    'accounts',
    'creditCards',
  ] as (keyof ItemProductsStatusDetail)[]

  return PRODUCT_KEYS.some((product) =>
    item.statusDetail?.[product]?.warnings.some((warning) =>
      warning.message.toLowerCase().includes('user needs to authorize'),
    ),
  )
}
