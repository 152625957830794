import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Alert, Button, Grid, Header } from '@pluggyai/ui'
import { Progress } from 'semantic-ui-react'

import { usePrevious } from '../../../../utils/hooks/usePrevious'
import { Title } from '../../Title'
import { ConnectorHeader } from '../ConnectorHeader'
import { Props } from './ConnectStatus.types'
import { useStepTooLongSentryReport } from './hooks/useStepTooLongSentryReport'
import { StepStatus } from './StepStatus'
import {
  CONNECTION_STEP,
  isExecutionError,
  mapExecutionStatusToStep,
  resolveStepToShowI18nKey,
  validateIfNeedsToAuthorizeProducts,
} from './utils'

import './ConnectStatus.css'

// after 30s a message is shown with the average time of the connector
export const CONNECTION_SLOW_THRESHOLD_MS = 30_000 // 30 seconds

const totalSteps = CONNECTION_STEP.FINISHED

const ConnectStatus = ({
  hideConnectStatusStepDetail,
  connector,
  item,
  onSuccess,
  onGoToErrorStatus,
  onClose,
  isUpdate,
}: Props) => {
  const { t } = useTranslation()

  const [progress, setProgress] = useState(0)

  const [currentStep, setCurrentStep] = useState<CONNECTION_STEP>(
    CONNECTION_STEP.LOGIN_IN_PROGRESS,
  )

  const [stepElapsedTime, setStepElapsedTime] = useState(0)
  const [connectionStartTime, setConnectionStartTime] = useState<number>(0)

  // Timer to send a "step too long" Sentry report, this is important to track & debug potential pluggy-api inconsistent response handling
  // Related bug report: https://pluggy.atlassian.net/browse/DVT-511
  useStepTooLongSentryReport({
    currentStep,
    item,
    stepElapsedTime,
  })

  useEffect(() => {
    const connectionStep = mapExecutionStatusToStep(item)

    if (isExecutionError(connectionStep)) {
      onGoToErrorStatus()
      return
    }
    if (connectionStep === currentStep) {
      // don't set the same step again
      return
    }
    setCurrentStep(connectionStep)
  }, [item, currentStep, onGoToErrorStatus])

  // interval timer to advance progress bar over time while waiting for next step
  const intervalRef = useRef<NodeJS.Timeout>()

  const previousStep = usePrevious(currentStep)
  useEffect(() => {
    const isInProgress = currentStep >= 0
    if (!isInProgress) {
      // not started, or already finished -> clear interval
      if (intervalRef.current) {
        clearInterval(intervalRef.current)
      }
      return
    }
    if (previousStep === currentStep) {
      // is still same step, do nothing
      return
    }

    if (intervalRef.current) {
      // current step updated -> refresh interval
      clearInterval(intervalRef.current)
    }
    if (currentStep === totalSteps) {
      // done
      setProgress(100)
      setStepElapsedTime(0)
      return
    }

    // progress started -> init interval
    intervalRef.current = setInterval(() => {
      const stepProgress = 1 / totalSteps
      const currentStepProgress = currentStep * stepProgress

      const currentElapsedTime = Date.now() - connectionStartTime
      setStepElapsedTime(currentElapsedTime)

      const currentElapsedSeconds = currentElapsedTime / 1000
      // function to add decrementally-gradual progress, capped at the next step progress value
      const timeProgress =
        stepProgress * (1 / totalSteps - 1 / currentElapsedSeconds)

      const activeProgress = currentStepProgress + timeProgress
      const activeProgressPercent = Math.floor(activeProgress * 100)
      if (activeProgressPercent < progress) {
        // got a new active progress *less* than the last one, this can happen if steps complete in different order.
        // for now, we just ignore it
        // TODO rework step to show progress to actual number of steps completed VS connector expected ones.
        return
      }
      setProgress((prevProgress) =>
        Math.max(prevProgress, activeProgressPercent),
      )
    }, 500)

    // disabling eslint error because it's a false positive in this case
    // eslint-disable-next-line consistent-return
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current)
      }
    }
  }, [connectionStartTime, currentStep, previousStep, progress])

  useEffect(() => {
    if (currentStep !== CONNECTION_STEP.LOGIN_IN_PROGRESS) {
      return
    }
    // if connection is starting -> save start time
    setConnectionStartTime(Date.now())
  }, [currentStep])

  useEffect(() => {
    if (progress === 100) {
      onSuccess()
    }
  }, [progress, onSuccess])

  const stepToShow = useRef<CONNECTION_STEP>(currentStep)

  useEffect(() => {
    if (currentStep === CONNECTION_STEP.UNKNOWN) {
      // unknown step, show the last known step
      return
    }

    stepToShow.current = currentStep
  }, [currentStep])

  const resolvedStepToShowI18nKey = resolveStepToShowI18nKey(
    stepToShow.current,
    hideConnectStatusStepDetail,
  )

  const currentStepName = t(resolvedStepToShowI18nKey)

  const needsToAuthorizeProducts = validateIfNeedsToAuthorizeProducts(item)

  return (
    <div
      className={`connect-status ${
        progress === 100 ? 'completed' : 'in-progress'
      }`}
    >
      <div className="connect-status-body">
        <Title>
          {isUpdate
            ? t('connectStatus.header.updating')
            : t('connectStatus.header.connecting')}
        </Title>

        <div className="accounts-alert-container">
          {item.connector.isOpenFinance && needsToAuthorizeProducts && (
            <Alert
              type={'warning'}
              message={
                'Conexão bem-sucedida, mas você precisa autorizar o produto ‘CONTAS’ no seu homebanking.'
              }
            />
          )}
        </div>
        <ConnectorHeader connector={connector} />
        {progress >= 0 && (
          <div className={'progress-bar'}>
            <Header className={'progress-value'}>{`${progress}%`}</Header>
            <Progress percent={progress} size={'tiny'} />
          </div>
        )}
        <Grid
          columns={2}
          verticalAlign={'middle'}
          centered={true}
          textAlign={'left'}
          className={'steps-grid'}
        >
          <StepStatus
            currentStepName={currentStepName}
            inProgress={progress < 100}
          />
        </Grid>
        {item.status === 'UPDATED' && item.connector.isOpenFinance && (
          <a
            href="https://my.pluggy.ai/account"
            target="_blank"
            rel="noreferrer"
            className={'open-finance-permission-management'}
          >
            {t('connectStatus.open-finance-permission-management')}
          </a>
        )}
      </div>
      <div className="action-container">
        <Button primary onClick={onClose}>
          {t('connectStatus.action.close')}
        </Button>
      </div>
    </div>
  )
}

export default React.memo(ConnectStatus)
