import { captureException } from '@sentry/react'
import type { Item, ProductType } from 'pluggy-js'
import {
  call,
  delay,
  put,
  race,
  select,
  take,
  takeEvery,
} from 'redux-saga/effects'

import { getPluggyClient } from '../../lib/pluggyClient'
import {
  getAppProps,
  onErrorCallback,
  onEventCallback,
  onSuccessCallback,
} from '../../utils/appWrapper'
import { TrackEventName } from '../analytics/events'
import { track } from '../analytics/utils'
import { isQRConnector } from '../connector/utils'
import {
  CLEAR_CONNECT_ITEM,
  CLEAR_CONNECT_ITEM_ERROR,
  ClearConnectItemErrorAction,
  CREATE_ITEM_REQUEST,
  createItemFailure,
  CreateItemRequestAction,
  createItemSuccess,
  DELETE_ITEM_REQUEST,
  deleteItemFailure,
  DeleteItemRequestAction,
  deleteItemSuccess,
  FETCH_ITEM_REQUEST,
  fetchItemFailure,
  fetchItemRequest,
  FetchItemRequestAction,
  fetchItemSuccess,
  OAUTH_ITEM_ERROR_CALLBACK,
  OAUTH_ITEM_SUCCESS_CALLBACK,
  OauthItemErrorCallbackAction,
  OauthItemSuccessCallbackAction,
  POLL_ITEM_START,
  POLL_ITEM_STOP,
  SEND_MFA_ITEM_REQUEST,
  sendMFAItemFailure,
  SendMFAItemRequestAction,
  sendMFAItemSuccess,
  sendNotificationAction,
  startPollingItem,
  StartPollingItemAction,
  stopPollingItem,
  UPDATE_ITEM_REQUEST,
  updateItemFailure,
  updateItemRequest,
  UpdateItemRequestAction,
  updateItemSuccess,
} from './actions'
import { CurrentPollingState } from './reducer'
import {
  getActivePollingActions,
  getCurrentPolling,
  isPollingItemAnyAction,
} from './selectors'
import { apiCreateErrorToOnError, getPollingDurationInMs } from './utils'

export function* itemSaga() {
  yield takeEvery(CREATE_ITEM_REQUEST, handleCreateItemRequest)
  yield takeEvery(OAUTH_ITEM_SUCCESS_CALLBACK, handleOauthItemSuccessCallback)
  yield takeEvery(OAUTH_ITEM_ERROR_CALLBACK, handleOauthItemErrorCallback)
  yield takeEvery(UPDATE_ITEM_REQUEST, handleUpdateItemRequest)
  yield takeEvery(DELETE_ITEM_REQUEST, handleDeleteItemRequest)
  yield takeEvery(FETCH_ITEM_REQUEST, handleFetchItemRequest)
  yield takeEvery(POLL_ITEM_START, pollTaskWatcher)
  yield takeEvery(CLEAR_CONNECT_ITEM, handleClearConnectItem)
  yield takeEvery(CLEAR_CONNECT_ITEM_ERROR, handleClearConnectItemError)
  yield takeEvery(SEND_MFA_ITEM_REQUEST, handleSendMFAItemRequest)
}

function* handleCreateItemRequest(createItemAction: CreateItemRequestAction) {
  const {
    payload: { connector, parameters },
  } = createItemAction

  const { products: productsToExecute } = getAppProps()

  let products: ProductType[] | undefined = productsToExecute
  if (parameters.securityPortabilityData) {
    // is move previdencia request -> set 'products' filter to only include 'MOVE_SECURITY'
    products = ['MOVE_SECURITY']
  }

  try {
    const item: Item = yield call(() =>
      getPluggyClient().createItem(
        connector.id,
        parameters,
        undefined,
        products,
      ),
    )
    const {
      connector: { id: connectorId, name: connectorName, type: connectorType },
    } = item

    yield put(startPollingItem(item.id, createItemAction))
    yield put(createItemSuccess(item))
    track(TrackEventName.ITEM_CREATED, {
      itemId: item.id,
      connectorName,
      connectorId,
      connectorType,
    })
  } catch (error) {
    yield put(createItemFailure(error))
    onErrorCallback(apiCreateErrorToOnError(error))
  }
}

function* handleOauthItemSuccessCallback(
  oauthItemSuccessCallbackAction: OauthItemSuccessCallbackAction,
) {
  const {
    payload: { itemId },
  } = oauthItemSuccessCallbackAction

  try {
    const item: Item = yield call(() => getPluggyClient().fetchItem(itemId))
    yield put(startPollingItem(item.id, oauthItemSuccessCallbackAction))
    yield put(createItemSuccess(item))
  } catch (error) {
    yield put(createItemFailure(error))
  }
}

function* handleOauthItemErrorCallback(action: OauthItemErrorCallbackAction) {
  const { error } = action.payload
  const message = `Error creating Oauth item: ${error.message}`
  yield put(createItemFailure(message))
}

function* handleUpdateItemRequest(updateItemAction: UpdateItemRequestAction) {
  const {
    payload: { itemId, parameters, webhookUrl },
  } = updateItemAction

  try {
    const item: Item = yield call(() =>
      getPluggyClient().updateItem(itemId, parameters, webhookUrl),
    )
    yield put(startPollingItem(item.id, updateItemAction))
    yield put(updateItemSuccess(item))
  } catch (error) {
    yield put(updateItemFailure(error))
  }
}

function* handleDeleteItemRequest(action: DeleteItemRequestAction) {
  const { item } = action.payload
  try {
    const count: number = yield call(() =>
      getPluggyClient().deleteItem(item.id),
    )
    if (count <= 0) {
      throw new Error(`Could not delete item ${item.id}`)
    }
    yield put(deleteItemSuccess(item))
  } catch (error) {
    yield put(deleteItemFailure(item, error.message))
  }
}

function* handleFetchItemRequest(action: FetchItemRequestAction) {
  const { id } = action.payload
  try {
    const item: Item | undefined = yield call(() =>
      getPluggyClient().fetchItem(id),
    )
    if (!item) {
      throw new Error('Could not retrieve item')
    }
    yield put(fetchItemSuccess(item))
    onEventCallback({ event: 'ITEM_RESPONSE', item })
  } catch (error) {
    yield put(fetchItemFailure(id, error.message))
  }
}

const ITEM_STATUS_POLL_INTERVAL = 2500 // 2.5 seconds
const ITEM_QR_POLL_INTERVAL = 500 // 0.5 seconds

function getPollTask(action: StartPollingItemAction) {
  const { itemId, originalAction } = action.payload

  return function* pollTask() {
    let shouldContinuePolling = true

    while (shouldContinuePolling) {
      let item: Item | undefined

      // fetch item request
      try {
        item = (yield call(() => getPluggyClient().fetchItem(itemId))) as Item
      } catch (error) {
        console.error('Unexpected error polling item status:', error)
        error.message = `Unexpected error polling item status: ${error.message}`
        captureException(error)

        track(TrackEventName.ITEM_POLLING_FINISHED, {
          itemId,
          status: 'failure',
          errorMessage: error.message,
          connectorId: item?.connector.id,
          connectorName: item?.connector.name,
        })

        yield put(fetchItemFailure(itemId, error))
        onErrorCallback(error.message)
        break
      }

      yield put(fetchItemSuccess(item))
      onEventCallback({ event: 'ITEM_RESPONSE', item })

      shouldContinuePolling =
        // item is still in UPDATING status
        item.status === 'UPDATING' ||
        // polling was started after OAUTH_ITEM_SUCCESS_CALLBACK action, we continue waiting for the oauth result
        (originalAction.type === OAUTH_ITEM_SUCCESS_CALLBACK &&
          item.status === 'WAITING_USER_INPUT' &&
          (item.parameter?.type as unknown) === 'oauth') ||
        // item is in WAITING_USER_ACTION status, continue until the external action is solved
        item.executionStatus === 'WAITING_USER_ACTION' ||
        // item is in WAITING_USER_INPUT status but no 'parameter' data, continue trying until it's present
        (item.status === 'WAITING_USER_INPUT' && item.parameter === null)

      if (shouldContinuePolling) {
        // wait a delay and check again
        const itemPollInterval =
          item.executionStatus === 'WAITING_USER_ACTION' &&
          isQRConnector(item.connector)
            ? // note: if it's WAITING_USER_ACTION with a QR, poll more frequently
              ITEM_QR_POLL_INTERVAL
            : ITEM_STATUS_POLL_INTERVAL

        yield delay(itemPollInterval)
        continue
      }

      // item poll finished, check if resulted in success or error
      const isSuccess = item.status === 'UPDATED'
      const isError = ['LOGIN_ERROR', 'OUTDATED'].includes(item.status)

      const notification = isSuccess
        ? {
            level: 'success',
            title: 'Item was synced successfully',
            message: `The item <b>${item.id}</b> was correctly connected with the institution <b>${item.connector.name}</b>`,
            position: 'bc',
          }
        : isError
        ? {
            level: 'warning',
            title: 'Item was not sync successfully!',
            message: `The item <b>${item.id}</b> couldn't connect with the institution <b>${item.connector.name}</b>. ${item.error?.message}`,
            position: 'bc',
          }
        : null

      if (isSuccess) {
        onSuccessCallback({ item })
      } else if (isError) {
        onErrorCallback({
          message: 'Item was not sync successfully',
          data: { item },
        })
      }

      if (isSuccess || isError) {
        const {
          connector: { id: connectorId, name: connectorName },
        } = item

        const currentPolling: CurrentPollingState = yield select(
          getCurrentPolling,
        )

        const { start: pollingStartDate } = currentPolling

        // calculating pollingEndDate here because it's not available in the selector yet
        const pollingEndDate = new Date()

        const pollingDurationInMs = getPollingDurationInMs(
          pollingStartDate,
          pollingEndDate,
        )

        track(TrackEventName.ITEM_POLLING_FINISHED, {
          itemId: item.id,
          itemStatus: item.status,
          executionStatus: item.executionStatus,
          status: isSuccess ? 'success' : 'error',
          connectorId,
          connectorName,
          pollingDurationInMs,
          pollingStartDate,
          pollingEndDate,
        })
      }

      if (notification) {
        yield put(sendNotificationAction(notification))
      }

      const is1StepMfa = item.connector.credentials.some((c) => c.mfa)
      const is2StepMfa = item.connector.hasMFA

      if (
        item.status === 'OUTDATED' &&
        (item.executionStatus === 'INVALID_CREDENTIALS_MFA' ||
          item.executionStatus === 'USER_INPUT_TIMEOUT') &&
        is2StepMfa &&
        !is1StepMfa
      ) {
        // the MFA (2-step) submitted was incorrect, update to refresh the MFA request.
        yield put(updateItemRequest(itemId))
      }
    }

    // stopped polling
    yield put(stopPollingItem(itemId))
  }
}

function* pollTaskWatcher(action: StartPollingItemAction) {
  yield race([call(getPollTask(action)), take(POLL_ITEM_STOP)])
}

function* handleClearConnectItem() {
  // stop item polling to properly clear state
  // Note: we are handling it as an array for type consistency,
  //  but in general there shouldn't be more than 1 active polling state at a time.
  const activePollingActions: StartPollingItemAction[] = yield select(
    getActivePollingActions,
  )
  if (activePollingActions.length === 0) {
    // was not polling, no need to stop
    return
  }

  for (const activePollingAction of activePollingActions) {
    const {
      payload: { itemId },
    } = activePollingAction

    yield put(stopPollingItem(itemId))
  }
}

function* handleClearConnectItemError({
  payload: { itemId },
}: ClearConnectItemErrorAction) {
  // stop item polling to properly clear state
  const isPolling: boolean = yield select(isPollingItemAnyAction)

  if (!isPolling) {
    // was not polling, no need to stop
    return
  }
  yield put(stopPollingItem(itemId))
}

function* handleSendMFAItemRequest(
  sendMfaItemAction: SendMFAItemRequestAction,
) {
  const {
    payload: { itemId, parameters },
  } = sendMfaItemAction

  try {
    const item: Item = yield call(() =>
      getPluggyClient().updateItemMFA(itemId, parameters),
    )
    yield put(startPollingItem(item.id, sendMfaItemAction))
    yield put(sendMFAItemSuccess(item))
  } catch (error) {
    // 404 -> no MFA for this item
    // other: MFA was invalid
    yield put(fetchItemRequest(itemId))
    yield put(sendMFAItemFailure(error))
  }
}
